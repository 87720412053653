import React, { useEffect, useState } from "react";
import Collection_tbl from "../Components/Collection_tbl";
import styles from "../Styles/Dashboard.module.css";
import Single_tab from "../Components/Single_tab";
import MenuAppBar from "../Components/Header";
import Drawer_customer from "../Components/Drawer";
import Swal from "sweetalert2";
import loader_img from "../Media/Business_chart.gif"
import axios from "axios";
import Footer from "../Components/footer";
import moment from "moment";
import dayjs from "dayjs";

// import { Worker } from '@react-pdf-viewer/core';

// import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';


// import { pdfjs } from 'react-pdf';

// import { Document, Page } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



function Download_report({ base_url }) {


  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let profile = JSON?.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");

  const [selected_flag, setSelected_Flag] = useState("");
  const [report_data, setReport_Data] = useState([]);
  const [file_path, setFile_Path] = useState("");
  const [report_sample, setReport_Sample] = useState([]);
  const [sample_name, setSample_Name] = useState("");

  const [report_load,setReport_Load] = useState(false);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");

  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");
   const [check_flag,setCheck_Flag] = useState(false)

const [row_status,setRow_Status] = useState(null)
  

  const data_check = [1, 2, 1, 1];

  const get_report_list = async (val,month,year) => {
    setReport_Load(true);
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    formData.append("category_name", val);
    formData.append("collection_month", selectedMonth == null?"": month + " "+ year);

    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customers-report-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setReport_Load(false)
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
          setReport_Data(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setReport_Load(false)
        
      });
  };

  const get_tab_list = async()=>{
    setReport_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    formData.append("collection_month", selectedMonth == null?"": send_month + " "+ send_year);
    // formData.append("collection_month", send_month + " "+ send_year);

    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customers-report-tab-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setReport_Load(false)
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
          let count_flag=false;
          setReport_Sample(res?.data?.data);
          for(let i=0; i<res?.data?.data?.length; i++){
            if(res?.data?.data[i]?.total_count != 0){
              count_flag = true
            }
          }
          setCheck_Flag(count_flag)
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setReport_Load(false)
        
      });
  }


  
  const handleMonth = (date, dateString) => {
    setFile_Path("");
    if (date) {
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
      setSample_Name("");
      setSelected_Flag("");
      setReport_Data([])
      // if(sample_name != ""){
      //   get_report_list(sample_name,formattedMon,formattedYear)
      // }


    } else {
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSend_year(new_year);
      setSend_month(new_month);
      setSelectedMonth(null);
      setSample_Name("");
      setSelected_Flag("");
      setReport_Data([])
      // if(sample_name != ""){
      // get_report_list(sample_name,new_month,new_year)
      // }

    }
  };

  // useEffect(() => {
  //   if (token != "" && token != null) {
  //     get_report_list();
  //   }
  // }, [sample_name]);


  useEffect(()=>{
    if (token != "" && token != null) {
      get_tab_list();      
    }
  },[selectedMonth])



  return (
    <>
      {/* <MenuAppBar/> */}
      <Drawer_customer />
     {report_load?<div><img id={styles.loader} src={loader_img}/></div>: <div
        className="container-fluid"
        style={{ paddingLeft: "5em", marginTop: "-2rem",position:"relative" }}
      >
        {/* <div className='row mt-2'>
              <div className='row mb-2' id={styles.service_tab_contain}>
                {report_sample?.map((ele)=>(
                  <Single_tab ele={ele} selected_flag={selected_flag} setSelected_Flag={setSelected_Flag} setSample_Name={setSample_Name} />
                ))}
   
              </div>
         </div>

         <div className='row'>
               <div className='col-sm-12 col-md-7'>
                   <Collection_tbl  data={report_data} setFile_Path={setFile_Path} />
               </div>
               <div className='col-sm-12 col-md-5' id={styles.pdf_msg} style={{boxShadow:file_path == ""?"rgba(0, 0, 0, 0.35) 0px 5px 15px":""}}>
            {file_path == ""?<p style={{marginTop:"20%"}}>Please Select any line item to view report</p>: <iframe src={`${file_path}#toolbar=0`} id={styles.pdf_contain} frameborder="0">              
             </iframe>}
               </div>
         </div> */}

        <div className="row">
          <div className="col-sm-12 col-md-8">
            <div className="row" id={styles.download_header}>
              <h6>Download Report</h6>
            </div>
            <div className="row mt-3" id={styles.service_tab_contain}>
              {report_sample?.map((ele) => (
                <Single_tab
                  ele={ele}
                  selected_flag={selected_flag}
                  setSelected_Flag={setSelected_Flag}
                  setSample_Name={setSample_Name}
                  setFile_Path={setFile_Path}
                  get_report_list={get_report_list}
                  send_month={send_month}
                  send_year={send_year}
                />
              ))}
            </div>
            <div className="row mt-3" style={{marginBottom:"5vw"}}>
              <Collection_tbl data={report_data} setFile_Path={setFile_Path}  selected_flag={selected_flag} count_flag={check_flag} setRow_Status={setRow_Status} 
              handleMonth={handleMonth}
              selectedMonth={selectedMonth}
              />
            </div>
          </div>
          <div
            className="col-sm-12 col-md-4"
            id={styles.pdf_msg}
            style={{
              boxShadow:
              (file_path == "" || row_status !=1) ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "",
            }}
          >
            {(file_path == "" || row_status !=1)? (
              <p style={{ marginTop: "20%" }}>
                Please Select any line item to view report
              </p>
            ) : (
  

<>
<iframe
                src={`${file_path}#toolbar=0`}
                // src={`https://docs.google.com/gview?url=${file_path}&embedded=true`}
                id={styles.pdf_contain}
                frameborder="0"
              ></iframe>
                  {/* <Document file={file_path}>
 <Page pageNumber={1} />
 </Document> */}
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={file_path} />
              </Worker> */}
</>

              // <div style={{ position: 'relative', width: '500px', height: '500px' }}>
              //   <iframe src={file_path} width="100%" height="100%"></iframe>
              //   <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent' }}></div>
              // </div>


              // <embed id={styles.pdf_contain} src={`${file_path}#page=1&zoom=300`}></embed>
            )}
          </div>
        </div>
      </div>}
      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                    <p id={styles.copyright}>


                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>

                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}
          <Footer/>
    </>
  );
}

export default Download_report;
