import React, { useCallback, useRef, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import ReactReadMoreReadLess from "react-read-more-read-less";
import styles from "../Styles/Audit.module.css";

function Audit_piechart({ data, parameter, devayani_flag }) {
  const windowHeight = useRef(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(1);

  let dataArray = [[]];
  let pieData = [
    {
      id: 15200,
      value: data?.PERCENTAGE,
      name: "",
    },
    {
      id: 15450,
      value: 100 - data?.PERCENTAGE,
      name: "",
    },
  ];

  function getText(data) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = data;
    return divContainer.innerText || divContainer.textContent || "";
  }

  pieData?.forEach((ele, ind) => {
    dataArray.push({
      id: ind,
      value: ele?.value,
      name: "Testing",
    });
  });

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={
            devayani_flag && (parameter == "State" || parameter == "City")
            ?"#007cc3":
            devayani_flag && parameter == "Site"?
            data?.PERCENTAGE >= 0 && data?.PERCENTAGE <= 75
  ? "#EB5757"
  : data?.PERCENTAGE >75  && data?.PERCENTAGE <= 80
  ? "#F2C94C"
  : data?.PERCENTAGE > 80 && data?.PERCENTAGE <= 90
  ? "rgba(33, 150, 83, 0.75"
  : data?.PERCENTAGE > 90 && data?.PERCENTAGE <= 100
  ? "green":"":
            data?.PERCENTAGE >= 0 && data?.PERCENTAGE < 30
              ? "#EB5757"
              : data?.PERCENTAGE >= 30 && data?.PERCENTAGE < 50
              ? "rgba(235, 87, 87, 0.50)"
              : data?.PERCENTAGE >= 50 && data?.PERCENTAGE < 80
              ? "#F2C94C"
              : data?.PERCENTAGE >= 80 && data?.PERCENTAGE < 90
              ? "rgba(33, 150, 83, 0.75)"
              : "#219653"
          }
        >
          {data?.PERCENTAGE}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {/* <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          /> */}
        {/* <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{value}</text>: <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >₹ {`${new Intl.NumberFormat("en-IN").format(value)}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text> */}
      </g>
    );
  };
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
    "rgb(237 237 237)",
    data?.PERCENTAGE >= 0 && data?.PERCENTAGE < 30
      ? "#EB5757"
      : data?.PERCENTAGE >= 30 && data?.PERCENTAGE < 50
      ? "rgba(235, 87, 87, 0.50)"
      : data?.PERCENTAGE >= 50 && data?.PERCENTAGE < 80
      ? "#F2C94C"
      : data?.PERCENTAGE >= 80 && data?.PERCENTAGE < 90
      ? "rgba(33, 150, 83, 0.75"
      : "#219653",
  ];

  const colors_devyani = [
    "rgb(237 237 237)",
    data?.PERCENTAGE >= 0 && data?.PERCENTAGE <= 75
      ? "#EB5757"
      : data?.PERCENTAGE >75  && data?.PERCENTAGE <= 80
      ? "#F2C94C"
      : data?.PERCENTAGE > 80 && data?.PERCENTAGE <= 90
      ? "rgba(33, 150, 83, 0.75"
      : data?.PERCENTAGE > 90 && data?.PERCENTAGE <= 100
      ? "green"
      : "#219653",
  ];

  const colors_devyani_state_city = ["#007cc3"];

  return (
    <div>
      <div
        style={{ marginTop: "10%", pointerEvents: "none" }}
        id="client_piechart"
        // style={{border:"1px solid red"}}
        // style={{marginLeft:windowHeight?.current<950?"7vw":"0vw" }}
      >
        <PieChart width={200} height={280}>
          {/* <PieChart  width={510} height={380} > */}
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataArray}
            cx={
              windowHeight.current > 900
                ? 65
                : windowHeight.current < 760 && windowHeight.current >= 740
                ? 45
                : 55
            }
            cy={60}
            innerRadius={40}
            outerRadius={50}
            // cx={250}
            // cy={150}
            // innerRadius={50}
            // outerRadius={90}
            fill="rgb(237 237 237)"
            dataKey="value"
            onMouseEnter={onPieEnter}
            // paddingAngle={1}
          >
            {pieData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  devayani_flag && (parameter == "State" || parameter == "City")
                    ? colors_devyani_state_city[
                        index % colors_devyani_state_city.length
                      ]
                    : 
                    devayani_flag && parameter == "Site"?colors_devyani[index % colors_devyani.length]:
                    COLORS[index % COLORS.length]
                }
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
            ))}
          </Pie>
        </PieChart>

        {/* <p style={{ marginTop: "-10vw",fontSize: "14px",fontWeight:"600" }}><marquee scrollamount="2">{data?.NAME}</marquee> </p> */}
      </div>
      <ReactReadMoreReadLess
        sx={{ marginTop: "-100px" }}
        charLimit={20}
        readMoreText={"Read more ▼"}
        readLessText={"Read less ▲"}
        readMoreClassName={styles.readMoreClassName}
        readLessClassName={styles.readLessClassName}
      >
        {/* <div style={{ marginTop: "-10vw",fontSize: "14px",fontWeight:"600" }}>{data?.NAME}</div> */}
        {/* <text
          style={{ fontSize: "13px", fontWeight:"400" }}
          x={90}
          y={220}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {data?.SITES_COUNT}
        </text> */}
        {getText(data?.NAME)}
      </ReactReadMoreReadLess>
      {parameter == "City" || parameter == "State" ? (
        <p>({data?.SITES_COUNT})</p>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Audit_piechart;
