import React, { useState, useEffect } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import { Button, TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import login_img from "../Media/login_img.png";
import bg_img from "../Media/login_new.jpg";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { Menu, MenuItem, Tooltip } from "@mui/material";

function LoginPage({ base_url }) {
  const navigate = useNavigate();
  const storage = window.sessionStorage;
  const token = storage.getItem("token");

  const [mobileNo, setMobileNo] = useState("");
  const [show_inp, setShow_inp] = useState(false);
  const [show_timer, setShow_timer] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [show_btn, setShow_btn] = useState(false);
  const [session, setSession] = useState(null);
  const [load, setLoad] = useState(false);
  const [accData, setAccData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [year_show, setYear_Show] = useState(new Date());
  let info_arr = year_show?.toString()?.split(" ");

  const [send_btn, setSend_Btn] = useState(false);
  const [error_msg, setError_Msg] = useState("");

  let timer;

  //   const handleMobileChange = (e) => {
  //     if(e.target.value.length == 10){
  //         setMobileNo(e.target.value);
  //     }

  const start_timer = () => {
    setShow_timer(true);
    setShow_btn(false);
    setSeconds(30);
  };

  // const handleOtp = async () => {
  //     if (show_btn) {
  //       setOtp([]);
  //     }
  //     if (mobileNo?.length == 10) {
  //         try {
  //             let res = await fetch(
  //                 // `${base_url}/Signin.svc/Login/${mobileNo}`
  //                 );
  //             let data = await res.json();
  //             if (data.response.MESSAGE === "User not found.") {
  //               const Toast = Swal.mixin({
  //                 toast: true,
  //                 position: "top-end",
  //                 showConfirmButton: false,
  //                 timer: 1500,
  //                 timerProgressBar: true,
  //                 didOpen: (toast) => {
  //                   toast.addEventListener("mouseenter", Swal.stopTimer);
  //                   toast.addEventListener("mouseleave", Swal.resumeTimer);
  //                 },
  //               });
  //               Toast.fire({
  //                 icon: "error",
  //                 title: "User Not Found",
  //               });
  //             }
  //             if (data.response.MESSAGE === "OTP Sent successfully.") {
  //              setShow_inp(true);
  //             //   start_timer();
  //               send_otp();
  //             //   storage.setItem("ORGANIZATION_ID", data.ORGANIZATION_ID);
  //             //   storage.setItem("USER_ID", data.USER_ID);
  //             //   storage.setItem("IS_PRIMARY", data.IS_PRIMARY);
  //             //   storage.setItem("PLAN_EXPIRY", data.PLAN_EXPIRY);
  //             //   storage.setItem("PLAN_ID", data.PLAN_ID);
  //             //   storage.setItem("ROLE_ID", data.ROLE_ID);
  //             //   storage.setItem("USER_NAME", data.USER_NAME);
  //             //   storage.setItem("ORGANIZATION_TYPE", data.ORGANIZATION_TYPE);
  //             }
  //           } catch (error) {
  //             const Toast = Swal.mixin({
  //               toast: true,
  //               position: "top-end",
  //               showConfirmButton: false,
  //               timer: 1500,
  //               timerProgressBar: true,
  //               didOpen: (toast) => {
  //                 toast.addEventListener("mouseenter", Swal.stopTimer);
  //                 toast.addEventListener("mouseleave", Swal.resumeTimer);
  //               },
  //             });
  //             Toast.fire({
  //               icon: "error",
  //               title: "Something Went Wrong",
  //             });
  //           }
  //     } else {
  //         const Toast = Swal.mixin({
  //             toast: true,
  //             position: "top-end",
  //             showConfirmButton: false,
  //             timer: 1500,
  //             timerProgressBar: true,
  //             didOpen: (toast) => {
  //               toast.addEventListener("mouseenter", Swal.stopTimer);
  //               toast.addEventListener("mouseleave", Swal.resumeTimer);
  //             },
  //           });
  //           Toast.fire({
  //             icon: "info",
  //             title: "Please Enter Valid Mobile Number!",
  //           });
  //    }
  //   };

  const sending_otp = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      send_otp();
    }
  };

  const send_otp = async (send_mobile) => {
    setSend_Btn(true);
    if (send_mobile?.length == 10) {
      const formData = new FormData();
      formData.append("mobile_no", `${send_mobile}`);
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "Multipart/form-data",
        },
      };
      axios
        .post(`${base_url}/send-customer-otp`, formData, config, {
          mode: "no-cors",
        })
        .then((res) => {
          setError_Msg("");
          if (res?.data?.message == "OTP was sent successfully.") {
            start_timer();
            setSend_Btn(false);

            setShow_inp(true);
            setIsDisabled(true);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: res?.data?.message,
            });
          } else {
            setError_Msg(res?.data?.message);
            setSend_Btn(false);
            setShow_inp(false);
            setShow_timer(false);
            setShow_btn(true);
            setIsDisabled(false);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "error",
              title: res?.data?.message,
            });
          }
        })
        .catch((err) => {
          setError_Msg("");
          setSend_Btn(false);

          setShow_timer(false);
          setShow_inp(false);
          setShow_btn(true);
          setIsDisabled(false);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Please Enter Valid Number.",
          });
        });
    } else {
      setError_Msg("");
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Please Enter Valid Number.",
      });
      setSend_Btn(false);
    }
  };

  const handleOtp = (e) => {
    setMobileNo(e.target.value);
    // may be commented respect to previous design 
    if(e.target.value.length == 10){
     send_otp(e.target.value)
    }
  };



  const check_login = async (new_otp) => {
    const formData = new FormData();
    formData.append("mobile_no", `${mobileNo}`);
    formData.append("otp_no", `${new_otp}`);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "Multipart/form-data",
      },
    };
    axios
      .post(`${base_url}/customer-login`, formData, config, {
        mode: "no-cors",
      })
      .then((res) => {
        let profile = res.data["user"].profile_details;
        let token = res.data["user"].token.access_token;
        storage.setItem("profile", JSON.stringify(profile));
        storage.setItem("token", JSON.stringify(token));
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Login Successful!",
        });
        storage.setItem("login_mobile_no", mobileNo);
        if (
          base_url == "https://beta-hub.equinoxlab.com/api"
            ? profile?.id == 13424 :profile?.id == 13424
            // || profile?.id == 1031 
        ) {
          // if(profile?.id == 13424 || profile?.id == 14816){
          navigate("/schedules_change");
        }
      //  else if (
      //     base_url == "https://beta-hub.equinoxlab.com/api"
      //       ?  profile?.id == 10372 
      //       : profile?.id == 14816 
      //   ) {
      //     navigate("/compass_dashboard");
      //   }
        
        else if (mobileNo == "7304496662") {
          navigate("/zomato_dashboard");
        } else {
          navigate("/accountsList");
        }
      })
      .catch((err) => {
        setOtp("");
        setIsDisabled(false);
        setShow_btn(true);
        setShow_timer(false);

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Please Enter Valid OTP.",
        });
      });
  };

  const handleLogin = (newValue) => {
    setOtp(newValue);
    if (newValue?.length == 6) {
      check_login(newValue);
    }
  };

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    if (seconds == 0) {
      clearInterval(timer);
      setShow_timer(false);
      setShow_btn(true);
      setIsDisabled(false);
    }
    return () => clearInterval(timer);
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" id={styles.top_bar}>
            <div id={styles.container}>
              <div className="row">
                <div className="col-xl-12 d-flex flex-row ">
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <PhoneIcon />
                    </div>

                    <a href="tel:02250647422">022 506 474 22</a>
                  </div>
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <EmailIcon />
                    </div>

                    <a href="mailto:contact@equinoxlab.com">
                      contact@equinoxlab.com
                    </a>
                  </div>
                  <div
                    style={{ marginLeft: "auto" }}
                    id={styles.top_bar_contact_item}
                  ></div>

                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_social}>
                      <ul id={styles.social_icons}>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/equinox-labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Linkedin" placement="bottom">
                              <LinkedInIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.instagram.com/equinox_labs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Instagram" placement="bottom">
                              <InstagramIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.facebook.com/equinoxlabs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Facebook" placement="bottom">
                              <FacebookOutlinedIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/Equinox_Labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Twitter" placement="bottom">
                              <XIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.youtube.com/@equinoxlabs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Youtube" placement="bottom">
                              <YouTubeIcon />
                            </Tooltip>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.equinoxLogo} col-12`}>
            {/* <img style={{marginTop:"10px"}} src={equinoxLogo} alt="" /> */}

            <img style={{ marginTop: "10px" }} src={hubcslogo} alt="" />
          </div>

          <div className="col-12">
            <div
              className="row"
              style={{ justifyContent: "space-around", alignItems: "center" }}
            >
              <div className="col-7">
                <img id={styles.login_pic} src={bg_img} alt="" />
              </div>

              <div className="col-4">
                <div id={styles.loginCard}>
                  <div className={styles.welcome_header}>
                    <h5>Welcome to Equinox Labs</h5>
                    <p className={styles.register_msg}>
                      If you are not a Equinox Client yet please&nbsp;
                      <a
                        href="https://www.equinoxlab.com/contact.php"
                        target="_blank"
                      >
                        click here
                      </a>
                      &nbsp;to register yourself, Our team will help you
                      on-board
                    </p>
                  </div>
                  <div>
                    <TextField
                      id="standard-basic"
                      className={styles.number_inp}
                      label="Enter your Mobile Number"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      inputProps={{ maxLength: 10 }}
                      variant="standard"
                      sx={{
                        width: "80%",
                        fontSize: "18px",
                        color: "#44546A",
                        fontFamily: "Roboto",
                      }}
                      // onKeyUp={sending_otp}
                      onChange={handleOtp}
                          // may be commented respect to previous design 
                      disabled={send_btn || isDisabled}
                      // disabled={isDisabled}
                    />
                    {error_msg?.length > 0 ? (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                          textAlign: "right",
                          width: "80%",
                          color: "red",
                        }}
                      >
                        {error_msg}
                      </p>
                    ) : (
                      <></>
                    )}

                    {/* <button
                      className="btn btn-primary btn-sm"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        wordWrap: "break-word",
                        width: "50%",
                        borderRadius: "24px",
                        marginTop: error_msg?.length > 0 ? "" : "3.5vh",
                      }}
                      onClick={send_otp}
                      disabled={send_btn || isDisabled}
                    >
                      Send OTP
                    </button> */}


                    {/* <div id={styles.btnContainer} style={{ marginTop: "1vh" }}>
                      <p
                        style={{ color: "#007cc3", cursor: "pointer" }}
                        onClick={send_otp}
                      >
                        {show_btn ? "Resend OTP" : ""}
                      </p>
                    </div> */}

                    {show_inp ? (
                      <>
                        <div>
                          <p className={styles.otpLabel}>Enter OTP</p>
                          <MuiOtpInput
                            value={otp}
                            length={6}
                            onChange={(newValue) => handleLogin(newValue)}
                            sx={{
                              width: "80%",
                              margin: "auto",

                              // marginTop: "30px",
                              gap: "10px",
                            }}
                            required
                            // onKeyUp={handleInput}
                          />
                        </div>
                      </>
                     ) : (
                      <></>
                    )} 

                    {show_timer ? (
                      <div>
                        <p
                          id={styles.timer}
                          style={{
                            color: seconds < 10 ? "red" : "green",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          <span>Resend in</span> {seconds} Seconds
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* <button
                      className="btn btn-primary btn-sm"
                      style={{
                        color:"white",
                        fontSize:"14px",
                        fontFamily:"Roboto",
                        wordWrap: 'break-word',
                        width: "50%",
                        borderRadius:"24px",
                        marginTop: show_timer ? "0px" : "5vh",
                    
                      }}
                      onClick={check_login}
                      disabled= {otp?.length==6?false:true}
                    >
                      Log In
                    </button> */}
                  </div>
                  <div className={styles.cardFooter}>
                    <p>
                      In case you face any issue while logging in the portal
                      please reach out to us on contact@equinoxlab.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <hr/> */}

          <div id={styles.footer_container}>
            <div className="col-12">
              <div className="row" id={styles.footerCopyright}>
                <div className="col-9">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                  <p id={styles.copyright}>
                    {/* Copyright : Equinox Labs Pvt ltd-{info_arr[3]} */}
                    Copyright © {info_arr[3]} Equinox Labs Private Limited
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
