import React, { useEffect, useState } from "react";
import Drawer_customer from "../Components/Drawer";
import Capa_dashboard_tbl from "../Components/Capa_dashboard_tbl";
import styles from "../Styles/Audit.module.css";
import loader_img from "../Media/Business_chart.gif";
import moment from "moment";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';


import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

function Capa_dashboard({ base_url }) {
  const storage = window.sessionStorage;

  let token = storage.getItem("token");
  let mobile_no = storage.getItem("login_mobile_no");
  const [capa_data, setCapa_Data] = useState([]);
  const [capa_load, setCapa_Load] = useState(false);

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [selectedFromDate, setSelectedFromDate] = useState(firstDay);
  const [selectedToDate, setSelectedToDate] = useState(lastDay);

  const [fill_status, setFill_status] = useState("");
  const [fill_data, setFill_data] = useState([]);

  const get_capa_details = async (start_date, end_date) => {
    setCapa_Load(true);
    setCapa_Data([]);
    setFill_data([]);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_NC_LOG_DETAILS/${newEndDate}/${newStartDate}/${mobile_no}`
      );
      let data = await res.json();
      setCapa_Load(false);
      setCapa_Data(data?.data);
    } catch (error) {
      setCapa_Load(false);
    }
  };

  const handleRangeChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
    }
  };

  let verify_count = 0;
  let submitted_count = 0;
  let open_count = 0;
  let expire_count = 0;

  capa_data?.forEach((ele) => {
    if (ele.STATUS == "OPEN") {
      open_count++;
    } else if (ele.STATUS == "VERIFY") {
      verify_count++;
    } else if (ele.STATUS == "SUBMITTED") {
      submitted_count++;
    } else if (ele.STATUS == "EXPIRE") {
      expire_count++;
    } else {
      let x = 1;
    }
  });

  const handleFilter = (val_filter) => {
    if (val_filter == "Total") {
      setFill_status("");
    } else {
      setFill_status(val_filter);

      let filter_data = capa_data?.filter((ele) => {
        return ele.STATUS == val_filter;
      });
      setFill_data(filter_data);
    }
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_capa_details(selectedFromDate, selectedToDate);
    }
  }, [selectedFromDate, selectedToDate]);
  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last week',
      value: [startOfWeek(addWeeks(new Date(), -1)), endOfWeek(addWeeks(new Date(), -1))],
      placement: 'left'
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{  paddingTop: "0em", paddingRight: "1em" }}
      >
        {capa_load ? (
          <div className="col-12" id={styles.capa_loader_contain}>
            <img src={loader_img} alt="" />
          </div>
        ) : (
          <>
          <div className="row" style={{marginTop:"-30px"}} >

            <div className="col-sm-6 col-md-9"></div>
             <div className="col-sm-6 col-md-3">
             <DateRangePicker
 format = "dd MMM yyyy"
      ranges={predefinedRanges}
      // placeholder="Placement left"
      style={{ width: 300 }}
      value={[selectedFromDate,selectedToDate]}
      onChange={handleRangeChange}
      // onShortcutClick={(shortcut, event) => {
      // }}
      preventOverflow
    />
             </div>
          </div>
            <div className="row mt-4" id={styles.capa_status_contain}>
              <div
                className="col-sm-12 col-md-2"
                style={{
                  backgroundColor: fill_status == "" ? "#007cc3" : "",
                  color: fill_status == "" ? "white" : "",
                }}
                onClick={() => handleFilter("Total")}
              >
                <p>Total : {capa_data?.length}</p>
              </div>
              <div
                className="col-sm-12 col-md-2"
                style={{
                  backgroundColor: fill_status == "VERIFIED" ? "#007cc3" : "",
                  color: fill_status == "VERIFIED" ? "white" : "",
                }}
                onClick={() => handleFilter("VERIFIED")}
              >
                <p>Verified : {verify_count}</p>
              </div>
              <div
                className="col-sm-12 col-md-2"
                style={{
                  backgroundColor: fill_status == "SUBMITTED" ? "#007cc3" : "",
                  color: fill_status == "SUBMITTED" ? "white" : "",
                }}
                onClick={() => handleFilter("SUBMITTED")}
              >
                <p>Submitted : {submitted_count}</p>
              </div>
              <div
                className="col-sm-12 col-md-2"
                style={{
                  backgroundColor: fill_status == "OPEN" ? "#007cc3" : "",
                  color: fill_status == "OPEN" ? "white" : "",
                }}
                onClick={() => handleFilter("OPEN")}
              >
                <p>Open : {open_count}</p>
              </div>
              <div
                className="col-sm-12 col-md-2"
                style={{
                  backgroundColor: fill_status == "EXPIRED" ? "#007cc3" : "",
                  color: fill_status == "EXPIRED" ? "white" : "",
                }}
                onClick={() => handleFilter("EXPIRED")}
              >
                <p>Expired : {expire_count}</p>
              </div>
            </div>
            <div className="row mt-4">
              <Capa_dashboard_tbl
                fill_status={fill_status}
                data={capa_data}
                fill_data={fill_data}

              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Capa_dashboard;
