import React, { useEffect, useState ,useRef } from "react";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Audit.module.css";
import logo from "../Media/equinox_logo.png";
import Audit_piechart from "../Components/Audit_piechart";
import loader_img from "../Media/Business_chart.gif";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import Overall_piechart from "../Components/Overall_piechart";
import Seven_days_graph from "../Components/Seven_days_graph";
import no_data_icon from "../Media/no_data_icon2.png"

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';


import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Iris_footer from "../Components/Iris_footer";
import City_wise_count from "../Components/City_wise_count";
import { useNavigate } from "react-router-dom";

function Audit_Dashboard_change({ base_url ,compass_flag}) {
  const storage = window.sessionStorage;
  const navigate = useNavigate();
  let token = storage.getItem("token");
  let mobile_no = storage.getItem("login_mobile_no");

  const [audit_site_data, setAudit_Site_Data] = useState([]);
  const [overall_data,setOverall_Data] = useState([])
  const [seven_days_data,setSeven_days_Data] = useState([]);
  const [graph_data,setGraph_Data] = useState([])
  const [client_details,setClient_Details] = useState([])
  const [parameter, setParameter] = useState("Site");
  const [site_load, setSite_Load] = useState(false);
  const [overall_load, setOverall_Load] = useState(false);
  const [seven_load, setseven_Load] = useState(false);
  const [client_load, setClient_Load] = useState(false);
const [city_count,setCity_Count] = useState([]);
const [count_load,setCount_Load] = useState(false)

  const [xlabel,setXlabel] = useState([])
  const [label_data,setLabel_Data] = useState([])
  let dateFormat = "MM/YYYY";
  let format2 = moment().format("MM/YYYY"); 
  const [month_filter, setMonth_filter] = useState(new Date());
  const [month,setMonth] = useState(Number(format2?.split("/")[0]))
  const [year,setYear] = useState(Number(format2?.split("/")[1]))

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
    const [selectedFromDate, setSelectedFromDate] = useState(firstDay);
    const [selectedToDate, setSelectedToDate] = useState(lastDay);

  const windowHeight = useRef(window.innerHeight);

  const handleParameter = (val) => {
    setParameter(val);
    // get_audit_site_details();
  };

  const get_audit_site_details = async (start_date,end_date) => {
    setSite_Load(true);
    setAudit_Site_Data([]);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_SITE_DETAILS/${newStartDate}/${newEndDate}/${parameter}/${mobile_no}`
      );
      let data = await res.json();
      setSite_Load(false);
      if(data?.response.CODE == "200"){
        setAudit_Site_Data(data?.data);
      }else{
        setAudit_Site_Data([])
      }

    } catch (error) {
      setSite_Load(false);
      setAudit_Site_Data([])
      ;
    }
  };

  const handleDateChange = (date, dateString) => {
    if(date){
      setMonth_filter(date);
      // setSend_Month_filter(moment(date.$d).format("MM/YYYY"));
      let date_info = moment(date.$d).format("MM/YYYY").split("/")
     setMonth(Number(date_info[0]))
     setYear(Number(date_info[1]))
    }else{
      setMonth_filter(new Date())
      let format2 = moment().format("MM/YYYY");
      setMonth(Number(format2?.split("/")[0]))
     setYear(Number(format2?.split("/")[1]))
      // setSend_Month_filter(format2)
    }

  };

  const get_overall_scores = async(start_date,end_date) =>{
    setOverall_Load(true)
    setOverall_Data([]);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_OVERALL_SCORES/${newStartDate}/${newEndDate}/${mobile_no}`);
      let data = await res.json();
      setOverall_Load(false)
      if(data?.response?.CODE == "200"){
        setOverall_Data(data?.data)
      }else{
        setOverall_Data([]);
      }

    } catch (error) {
      setOverall_Load(false)
      setOverall_Data([]);
      
    }
  }



  const get_seven_days_graph = async() =>{
    setseven_Load(true)
    setSeven_days_Data([])
    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_LAST_SEVEN_DAYS_SITE_DETAILS/${mobile_no}`);
      let data = await res.json();
      setseven_Load(false)  
      setGraph_Data(data?.data)
      let xlabel =[""];
      let seven_data =[null];
      let label_data =[""]
      for(let i=0; i<data?.data?.length; i++){
        xlabel.push(data?.data[i]?.AUDIT_END_ON);
        seven_data.push(data?.data[i]?.PERCENTAGE);
        label_data.push(data?.data[i]?.PERCENTAGE)
      }
      setXlabel(xlabel)
      setSeven_days_Data(seven_data)
    } catch (error) {
      setseven_Load(false)
      
    }
  }

  const get_client_details = async() =>{
    setClient_Load(true)
    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS/${mobile_no}`);
      let data = await res.json();
      setClient_Load(false)
      setClient_Details(data?.data)
    } catch (error) {
      setClient_Load(false)
      
    }
  }

  const get_citywise_counts = async(start_date,end_date) =>{
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    setCount_Load(true)
    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CITY_WISE_REPORT_COUNT/${newStartDate}/${newEndDate}/${mobile_no}`);
      let data = await res.json();
      setCount_Load(false)
      setCity_Count(data?.data)
    } catch (error) {
      setCount_Load(false)
      setCity_Count([])
    }
  }

  const handleRangeChange = (new_val)=>{
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
    }
  }


  useEffect(() => {
    if(token != "" && token != null){
    get_audit_site_details(selectedFromDate,selectedToDate);
    }
  }, [parameter,selectedFromDate]);

 useEffect(()=>{
  if(token != "" && token != null){
  get_overall_scores(selectedFromDate,selectedToDate);
  get_citywise_counts(selectedFromDate,selectedToDate);
  }
 },[selectedFromDate,selectedToDate])

 useEffect(()=>{
  if(token != "" && token != null){
   get_seven_days_graph();
   get_client_details();
  }
 },[])
 const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last week',
    value: [startOfWeek(addWeeks(new Date(), -1)), endOfWeek(addWeeks(new Date(), -1))],
    placement: 'left'
  },
  // {
  //   label: 'Last 7 days',
  //   value: [subDays(new Date(), 6), new Date()],
  //   placement: 'left'
  // },
  // {
  //   label: 'Last 30 days',
  //   value: [subDays(new Date(), 29), new Date()],
  //   placement: 'left'
  // },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left'
  },
  // {
  //   label: 'Last week',
  //   closeOverlay: false,
  //   value: value => {
  //     const [start = new Date()] = value || [];
  //     return [
  //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
  //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
  //     ];
  //   },
  //   appearance: 'default'
  // },
  // {
  //   label: 'Next week',
  //   closeOverlay: false,
  //   value: value => {
  //     const [start = new Date()] = value || [];
  //     return [
  //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
  //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
  //     ];
  //   },
  //   appearance: 'default'
  // }
];

  return (
    <>
     {compass_flag?<></>: <Drawer_customer />}
      {/* {(site_load || overall_load ||seven_load)? 
              <div className="container-fluid" style={{ paddingLeft: "5em", paddingTop: "0em", paddingRight: "1em" }}>
                <img id={styles.loader_pic} src={loader_img} alt="" />
              </div>
             : */}
               <div
        className="container-fluid"
        style={{
           paddingLeft: "1.5em",
           paddingTop: "0em", paddingRight: "1.5em" }}
      >
<div className="row"  id={styles.tabs_container} style={{marginTop:"-30px"}}>
  <div style={{ backgroundColor: "rgb(0 124 195 / 16%)"}} onClick={()=>navigate("/schedules_change")} className="col-1">
  <p >Summary</p>
  </div>
  <div style={{borderBottom:"2px solid #007cc3"
      ,backgroundColor: "rgb(0 124 195 / 16%)"
  }} onClick={()=>navigate("/audit_dashboard_change")} className="col-2">
  <p >State/City Wise</p>
  </div>
  <div style={{ backgroundColor: "rgb(0 124 195 / 16%)"}} onClick={()=>navigate("/audit_section_report_v2")} className="col-1">
  <p>Section Wise</p>
  </div>

</div>

        <div className="row mt-4">
          <div className="col-4">
            {/* <h4 style={{fontSize:"25px",fontWeight:"400",textAlign:"left",color: "#4F4F4F",visibility:compass_flag?"hidden":"visible"}}>{client_details[0]?.NAME}</h4> */}
          </div>
          <div className="col-4">
            {/* <h3 style={{fontSize: "32px",color:"#007cc3",fotnWeight:"600"}}>State/City Wise</h3> */}
            {site_load?<></>:
            // <Space direction="vertical">
            //   <DatePicker
            //     onChange={handleDateChange}
            //     picker="month"
            //     defaultValue={dayjs(format2, dateFormat)}
            //     value={dayjs(month_filter)}
            //     format="MMM-YYYY"
            //   />
            // </Space>
            <>
            <DateRangePicker
 format = "dd MMM yyyy"
      ranges={predefinedRanges}
      // placeholder="Placement left"
      style={{ width: 300 }}
      value={[selectedFromDate,selectedToDate]}
      onChange={handleRangeChange}
      // onShortcutClick={(shortcut, event) => {
      // }}
      preventOverflow
    />
             {/* <DateRangePicker oneTap showOneCalendar format = "dd MMM yyyy" hoverRange="week" onChange={handleWeekChange} value={[selectedFromDate,selectedToDate]} ranges={[selectedFromDate,selectedToDate]} /> */}
            </>
            }
          </div>
          {/* {client_load?<></>: <div className="col-4" id={styles.logo_contain}>
            <img src={client_details[0]?.LOGO_IMG} alt="" />
          </div>
          } */}
        </div>

       {site_load?<></>: 
       <div  id={styles.btn_contain}>

          <button
            onClick={() => handleParameter("Site")}
            style={{
              backgroundColor: parameter == "Site" ? "#007cc3" : "",
              color: parameter == "Site" ? "white" : "",
            }}
          >
            Site
          </button>
          <button
            onClick={() => handleParameter("City")}
            style={{
              backgroundColor: parameter == "City" ? "#007cc3" : "",
              color: parameter == "City" ? "white" : "",
            }}
          >
            City
          </button>
          <button
            onClick={() => handleParameter("State")}
            style={{
              backgroundColor: parameter == "State" ? "#007cc3" : "",
              color: parameter == "State" ? "white" : "",
            }}
          >
            State
          </button>
          <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
               <div style={{backgroundColor:"#EB5757",height:"15px",width:"15px"}}></div>
                <h6 style={{color:"#EB5757"}}>0% - 75%</h6>
               </div>
          <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
               <div style={{backgroundColor:"#F2C94C",height:"15px",width:"15px"}}></div>
                <h6 style={{color:"#F2C94C"}}>76% - 80%</h6>
               </div>
          <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
               <div style={{backgroundColor:"rgba(33, 150, 83, 0.75)",height:"15px",width:"15px"}}></div>
                <h6 style={{color:"rgba(33, 150, 83, 0.75)"}}>81% - 90%</h6>
               </div>
          <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
               <div style={{backgroundColor:"green",height:"15px",width:"15px"}}></div>
                <h6 style={{color:"green"}}>91% - 100%</h6>
               </div>
               </div>
        </div>}

        <div className="row mt-4">
        {site_load?<div style={{marginTop:"100px"}} className="col-7" id={styles.loader_contain}>
           <img src={loader_img} alt=""  />
        </div>: 
         (!site_load&&audit_site_data?.length == 0)?<div className="col-7">
         <img
           src={no_data_icon}
           alt=""
           style={{ height: "200px", width: "200px" }}
         />
        <h4>No Data Available</h4> 
        </div>:
        <div className="col-sm-12 col-md-7" id={styles.site_details_container}>

              <div className="row" >
                {audit_site_data?.map((ele) => (
                  <div className="col-2">
                    <Audit_piechart data={ele} parameter={parameter} devayani_flag={true} />
                  </div>
                ))}
              </div>
   
          </div>}

          {overall_load?<div id={styles.loader_container} className="col-5">
           <img src={loader_img} alt="" />
          </div>:
            (!overall_load&&overall_data?.length == 0)?<div className="col-5">
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
           <h4>No Data Available</h4> 
           </div>:
          <div className="col-5" id={styles.overall_container}>
                {/* <div className="col-2" id={styles.pie_contain}> */}
                <Overall_piechart data={overall_data} />
              {/* </div> */}
  
          </div>}
        </div>

<div className="row mt-4 mb-4">
{count_load?<div id={styles.loader_container} className="col-12">
           <img src={loader_img} alt="" />
          </div>: <div className="col-sm-12 col-md-12">
      <City_wise_count data={city_count} />
    </div>}
</div>

 <div className="row mt-2 mb-5" style={{position:"relative"}}>
        
{/* {seven_load?<div id={styles.loader_contain} className="col-9">
          <img src={loader_img} alt="" />
        </div>: 
        (!seven_load&&seven_days_data?.length == 0)?<div className="col-9">
        <img
          src={no_data_icon}
          alt=""
          style={{ height: "200px", width: "200px" }}
        />
       <h4>No Data Available</h4> 
       </div>:
        <div className="col-9" >
          <h6 id={styles.seven_header}>Last Seven Audits</h6>
          <Seven_days_graph xlabel={xlabel} data={seven_days_data} graph_data={graph_data} />
          </div>} */}


          {/* <div className="col-3" id={styles.info_container}>
               <div style={{marginTop:"5px"}}>
               <div style={{backgroundColor:"#EB5757"}}></div>
                <h6 style={{color:"#EB5757"}}>0% - 30%</h6>
               </div>
               <div>
               <div style={{backgroundColor:"rgba(235, 87, 87, 0.50)"}}></div>
                <h6 style={{color:"rgba(235, 87, 87, 0.50)"}}>30% - 50%</h6>
               </div>
               <div >
               <div style={{backgroundColor:"#F2C94C"}}></div>
                <h6 style={{color:"#F2C94C"}}>50% - 80%</h6>
               </div>
               <div >
               <div style={{backgroundColor:"rgba(33, 150, 83, 0.75)"}}></div>
                <h6 style={{color:"rgba(33, 150, 83, 0.75)"}}>80% - 90%</h6>
               </div>
               <div>
               <div style={{backgroundColor:"#219653"}}></div>
                <h6 style={{color:"#219653"}}>More than 90%</h6>
               </div>
          </div> */}

         </div>

      </div>
<Iris_footer/>

      {/* } */}
    </>
  );
}

export default Audit_Dashboard_change;
