import MUIDataTable from 'mui-datatables'
import React from 'react'
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Audit.module.css"

function City_wise_count({data}) {

const get_total = (param)=>{
  if(param == "SITE_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.SITE_COUNT);
    }, 0);
    return total_count;
  }
  else if(param == "CREATED_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.CREATED_COUNT);
    }, 0);
    return total_count;
  }
  else if(param == "SCHEDULE_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.SCHEDULE_COUNT);
    }, 0);
    return total_count;
  }
  else if(param == "QC_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.QC_COUNT);
    }, 0);
    return total_count;
  }
  else if(param == "REPORT_GENERATED_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.REPORT_GENERATED_COUNT);
    }, 0);
    return total_count;
  }
  else if(param == "PASS_COUNT"){
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.PASS_COUNT);
    }, 0);
    return total_count;
  }else{
    let total_count = data?.reduce((acc, ele) => {
      return acc + Number(ele?.FAIL_COUNT);
    }, 0);
    return total_count;
  }
}

    const options = {
        filterType: "checkbox",
      };

      const columns = [
        {
          name: "CITY_NAME",
          label: "City",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return (
                <p style={{padding:"5px"}}>{row}</p>
              )
            }
          },
        },
  
        {
          name: "SITE_COUNT",
          label: "Site",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "CREATED_COUNT",
          label: "Audit Created",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "SCHEDULE_COUNT",
          label: "Schedule",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "QC_COUNT",
          label: "QC",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "REPORT_GENERATED_COUNT",
          label: "Report Generated",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "PASS_COUNT",
          label: "Pass",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "FAIL_COUNT",
          label: "Fail",
          options: {
            filter: false,
            sort: true,
          },
        },
       

      
    ]    
  return (
    <div>
       <MUIDataTable
        title={
          <h4 id={styles.list_head}>
            Citywise Count
          </h4>
        }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          filter: false,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,

          customTableBodyFooterRender: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage,
            data
          ) => {
            const startIndex = page * rowsPerPage;
            const endIndex = (page + 1) * rowsPerPage;
            return (
              <TableFooter id={styles.table_footer}>
                <TableRow>
                  <TableCell id={styles.first_col}>
                    <p style={{textAlign:"center"}}>Grand Total</p>
                  </TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("SITE_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("CREATED_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("SCHEDULE_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("QC_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("REPORT_GENERATED_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("PASS_COUNT")}</p></TableCell>
                  <TableCell><p style={{textAlign:"center"}}>{get_total("FAIL_COUNT")}</p></TableCell>
                
                </TableRow>
              </TableFooter>
            );
          },
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
       
        }}
      />
    </div>
  )
}

export default City_wise_count
