import React from 'react'
import MUIDataTable from 'mui-datatables'
import styles from "../Styles/Audit.module.css"
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import { Tooltip } from '@mui/material';

function Capa_dashboard_tbl({fill_status,data,fill_data}) {


    const options = {
        filterType: "checkbox",
      };

      const columns = [
        {
            name: "RES_ID",
            label: "Res Id",
            options: {
              filter: false,
              sort: true,
            },
          },
        {
          name: "NAME",
          label: "Name",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return (
                <p>{row}</p>
              )
            }
          },
        },
        {
          name: "AUDIT_END_ON",
          label: "Audit Date",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "STATUS",
          label: "CAPA Status",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "CLIENT_LINK",
          label: "Link",
          options: {
            filter: false,
            sort: true,
            customBodyRender:(row)=>{
                return (
                    <>
                    <Tooltip title="Open CAPA" placement='left'>
                       <InsertLinkIcon
                sx={{ color: "#007cc3", cursor: "pointer" }}
                onClick={() => window.open(row, "_blank")}
              />
              </Tooltip>
                    </>
                )
            }
          },
        },
      
    ]    
  return (
    <div>
       <MUIDataTable
        title={
          <h4 id={styles.list_head}>
            CAPA Details
          </h4>
        }
        data={fill_status == "" ? data : fill_data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          filter: false,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,

          setRowProps: () => ({
            style: {
              cursor: "pointer",
            },
          }),

        

          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
       
        }}
      />
    </div>
  )
}

export default Capa_dashboard_tbl
