import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "../Styles/Audit.module.css"

function Section_data_table({data,header_data,handleShow_graph,
  setGraph_Site}) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleRow = (info)=>{
      setGraph_Site(info?.SITE_ID);
      handleShow_graph(info?.SITE_ID)
    }
  return (
    <Paper
    sx={{
      // width: index == 0 ? "40%" : "20%",
      // width: "94%",
      mb: 2,
      padding: "0px",
      marginBottom:"50px"
      // marginLeft:"5%"
      // height: "500px",
    }}
  >
    <TableContainer  className={styles.service_tbl}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            backgroundColor: "#007cc3",
            color: "#444a4f !important",
            position: "sticky",
            top: 0,
            zIndex: "99",
          }}
        >
          <TableRow>
              {header_data?.map((ele)=>(
                <>
                          {ele == "SITE_ID"?<></>: <TableCell   className={styles.th_unit}>
                          <h6 style={{fontWeight:"500"}}>{(ele == "Audit Date" || ele == "Site Name")?ele: `${ele} (%)`}</h6>
                         </TableCell>}
                         </>
              ))}
          </TableRow>
        </TableHead>
        {data?.length == "0" ? (
          <TableBody>
            {" "}
            <TableRow>
              <TableCell colSpan={11} className={styles.th_unit}>
                {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                <p style={{ textAlign: "center" }}>No Data Available</p>
              </TableCell>
              {/* </div> */}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody id={styles.body_content}>
            {(rowsPerPage > 0
              ? data?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : data
            )?.map((ele) => (
              <TableRow sx={{cursor:"pointer"}} onClick={()=>handleRow(ele)} >
                {Object.entries(ele).map(([k, v]) => (
                  <>
                    {k == "SITE_ID"?<></>:<TableCell title="Click here" id={styles.table_unit}>{v == null ? "0" : v}</TableCell>}
                    </>
                  ))}
                {/* <TableCell className={styles.th_unit}>
                  <p className="p mt-2" style={{ textAlign: "left" }}>
                    {ele.sales_person}
                  </p>
                </TableCell> */}
               
                
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
    <TablePagination
      id={styles.footer_div}
      class="footer_div"
      sx={{ backgroundColor: "#007cc3", color: "white" }}
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      count={data?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  )
}

export default Section_data_table