import React, { useEffect, useState, useRef } from "react";
import Drawer_customer from "../Components/Drawer";
import Scheduling_status from "../Components/Scheduling_status";
import Scheduling_Data_Table from "../Components/Scheduling_Data_Table";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import loader_img from "../Media/Business_chart.gif";
import styles from "../Styles/Audit.module.css";
import no_data_icon from "../Media/no_data_icon2.png";
import { DateRangePicker } from "rsuite";

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import Upcoming_schedule_tbl from "../Components/Upcoming_schedule_tbl";
import addWeeks from 'date-fns/addWeeks';
import Footer from "../Components/footer";
import Iris_footer from "../Components/Iris_footer";

function Schedules_List({ base_url,compass_flag }) {
  const storage = window.sessionStorage;
  let mobile_no = storage.getItem("login_mobile_no");
  let token = storage.getItem("token");

  const [schedulePieData, setSchedulePieData] = useState([]);
  const [scheduleTableData, setScheduleTableData] = useState([]);
  let dateFormat = "MM/YYYY";
  let format2 = moment().format("MM/YYYY");
  const [month_filter, setMonth_filter] = useState(new Date());
  const [month, setMonth] = useState(Number(format2?.split("/")[0]));
  const [year, setYear] = useState(Number(format2?.split("/")[1]));
  const [loadPie, setLoadPie] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [upcoming_Load, setUpcoming_Load] = useState(false);

  const [upcoming_data,setUpcoming_Data] = useState([])

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [selectedFromDate, setSelectedFromDate] = useState(firstDay);
  const [selectedToDate, setSelectedToDate] = useState(lastDay);

  const windowHeight = useRef(window.innerHeight);

  const handleDateChange = (date, dateString) => {
    if (date) {
      setMonth_filter(date);
      // setSend_Month_filter(moment(date.$d).format("MM/YYYY"));
      let date_info = moment(date.$d).format("MM/YYYY").split("/");
      setMonth(Number(date_info[0]));
      setYear(Number(date_info[1]));
    } else {
      setMonth_filter(new Date());
      let format2 = moment().format("MM/YYYY");
      setMonth(Number(format2?.split("/")[0]));
      setYear(Number(format2?.split("/")[1]));
      // setSend_Month_filter(format2)
    }
  };

  const get_dashboard_data_chart_status = async (start_date, end_date) => {
    setLoadPie(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(
        // `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_SITE_DETAILS/${month}/${year}/${parameter}/${mobile_no}`
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE/${newStartDate}/${newEndDate}/${mobile_no}`
      );
      let data = await res.json();
      setLoadPie(false);
      if (data?.response?.CODE == "200") {
        setSchedulePieData(data?.data);
      } else {
        setSchedulePieData([]);
      }
    } catch (error) {
      setLoadPie(false);
      setSchedulePieData([]);
      ;
    }
  };

  const get_dashboard_data_table = async (start_date, end_date) => {
    setLoadTable(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(
        // `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_SITE_DETAILS/${month}/${year}/${mobile_no}`
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE_TABLE/${newStartDate}/${newEndDate}/${mobile_no}`
      );
      let data = await res.json();
      setLoadTable(false);
      if (data?.response?.CODE == "200") {
        setScheduleTableData(data?.data);
      } else {
        setScheduleTableData([]);
      }
    } catch (error) {
      setLoadTable(false);
      setScheduleTableData([]);
      ;
    }
  };

  const get_upcoming_schedule = async() =>{
    setUpcoming_Load(true)
    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_UPCOMING_SCHEDULED/${mobile_no}`);
      let data = await res.json();
      setUpcoming_Load(false)
      if(data?.response?.CODE == "200"){
        setUpcoming_Data(data?.data)
      }else{
        setUpcoming_Data([])
      }
    } catch (error) {
      setUpcoming_Load(false)
      setUpcoming_Data([])
      
    }
  }

  const handleRangeChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
    }
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_dashboard_data_chart_status(selectedFromDate, selectedToDate);
      if(compass_flag){
        let x=1;
       }else{
        get_dashboard_data_table(selectedFromDate, selectedToDate);
       }
    }
  }, [selectedFromDate]);


  useEffect(()=>{
    if (token != "" && token != null) {
    if(compass_flag){
     let x=1;
    }else{
      get_upcoming_schedule();
    }
  }
  },[])

  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last week',
      value: [startOfWeek(addWeeks(new Date(), -1)), endOfWeek(addWeeks(new Date(), -1))],
      placement: 'left'
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{ paddingLeft: "5em", paddingTop: "0em", paddingRight: "1em" , marginTop:"-25px"}}
      >
        {/* <div className="row"> */}

       {/* {compass_flag? <div className="row">
           <h3 id={styles.client_name}>Compass</h3>
        </div>:<></>} */}
        <div className="row">
          <div className="col-sm-12 col-md-2">
            <h5 style={{ color: "#4F4F4F" }}>
              Total Site: {schedulePieData[0]?.TOTAL_SITES}
            </h5>
          </div>
          <div className="col-sm-12 col-md-7"></div>
          <div className="col-sm-12 col-md-3">
            {/* <Space direction="vertical">
              <DatePicker
                onChange={handleDateChange}
                picker="month"
                defaultValue={dayjs(format2, dateFormat)}
                value={dayjs(month_filter)}
                format="MMM-YYYY"
              />
            </Space> */}

            <DateRangePicker
              format="dd MMM yyyy"
              ranges={predefinedRanges}
              // placeholder="Placement left"
              style={{ width: 300 }}
              value={[selectedFromDate, selectedToDate]}
              onChange={handleRangeChange}
              // onShortcutClick={(shortcut, event) => {
   
              // }}
              preventOverflow
            />
          </div>
        </div>
        {loadPie ? (
          <div className="row" id={styles.loader_contain}>
            <img src={loader_img} alt="" />
          </div>
        ) : schedulePieData.length > 0 ? (
          <Scheduling_status data={schedulePieData[0]} />
        ) : (
          <div className="col-12">
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Available</h4>
          </div>
        )}
        {/* {compass_flag?<></>:<>
        {upcoming_Load?<div className="row" id={styles.loader_contain}>
            <img src={loader_img} alt="" />
          </div> :<div id={styles.schedule_tbl} className="row mt-2">
       <Upcoming_schedule_tbl data={upcoming_data}/>
        </div>}
        </>} */}

      {compass_flag?<></>: <>
        {loadTable ? (
          <div className="row mt-2" id={styles.loader_contain}>
            <img src={loader_img} alt="" />
          </div>
        ) : scheduleTableData.length > 0 ? (
          <div id={styles.schedule_tbl} className="row mt-4 mb-3">
            <Scheduling_Data_Table tableData={scheduleTableData} />
          </div>
        ) : (
          <div className="col-12">
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Available</h4>
          </div>
        )}
</>}
      </div>
      {/* </div> */}
      {/* <Footer/> */}
      <Iris_footer/>
    </>
  );
}

export default Schedules_List;
